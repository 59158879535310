<template>
    <div class="list">
        <div class="divTab">
            <div class="venue-list" v-for="(item,index) in venueInfoList" :key="index" @click="goDetail(index)">
                <img :src="item.thumbnail" alt="">
                <div class="venue-list-center">
                    <div>
                        <h2>{{item.name}}</h2>
                        <span class="grand-hall" v-if="item.type == 0">#小馆#</span>
                        <span class="grand-hall" v-if="item.type == 1">#大馆#</span>
                        <span class="grand-hall" v-if="item.type == 2">#体育场#</span>
                        <span class="grand-hall" v-if="item.type == 3">#篮球馆#</span>
                    </div>
                    <p>{{item.address}}</p>
                </div>
            </div>

            <div class="none_bg" v-if="venueInfoList.length == 0">
                <img src="../assets/img/none_bg.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
// 搜索
import { getVenueInfoByQuery } from "../api/home";
export default {
    data() {
        return {
            name: "",
            venueInfoList: [],
        };
    },
    created() {
        // console.log(this.$route.query);

        this.getlistss(this.$route.query.name);
    },
    mounted() {},
    watch: {
        // name(val, oldVal){//普通的watch监听
        //      console.log("a: "+val, oldVal);
        //      this.getlistss();

        // },


        $route(to, from) {
            let name = ""
            // console.log(this.$route.query.name);
            if (this.$route.query.name == undefined) {
                name = "";
                this.getlistss(name);
            } else {
                name = this.$route.query.name;
                this.getlistss(name);
            }
        },
    },
    methods: {
        getlistss(name) {
            getVenueInfoByQuery({
                name: name,
            }).then((res) => {
                this.venueInfoList = res.data.data.venueInfoList;
            });
        },

        goDetail(index) {
            this.id = this.venueInfoList[index].id;
            // console.log(this.id);
            this.$router.push({
                name: "detail",
                params: {
                    id: this.id,
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.list {
    width: 100%;
    .divTab {
        width: 100%;
        padding: 20px 30px 48px 45px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .venue-list {
            width: 350px;
            margin: 0 15px 15px 0;
            cursor: pointer;
            img {
                width: 100%;
                height: 225px;
                border-radius: 6px 6px 0px 0px;
            }
            .venue-list-center {
                width: 100%;
                height: 68px;
                background-color: #ffffff;
                border-radius: 0px 0px 6px 6px;
                margin-top: -3px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 15px;
                div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    h2 {
                        color: #282828;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
                p {
                    color: #bbbbbb;
                    font-size: 12px;
                    padding-top: 8px;
                }
            }
        }
        .none_bg{
            width:100%;
            min-height:75vh;
            display:flex;
            align-items: center;
            justify-content: center;
            img{
                width:200px;
                height:200px;
            }
        }
    }
}
</style>